import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import logo from '../Assets/logo.png';
import LoggedOut from './Header/LoggedOut';
import AuthScreen from './Header/AuthScreen';
import LoggedIn from './Header/LoggedIn';

const Header = () => {

  const isUserAuthenticated = useSelector(store => store?.states?.isUserAuthenticated);
  const userName = useSelector(store => store?.user?.email);
  const [renderAuthScreen, setRenderAuthScreen] = useState(false);
  return (
    <>
      <div className='w-screen flex items-center justify-between h-12 px-24 py-2'>
        <img
          className=' h-full'
          src={logo}
          alt="logo"
        />
        {isUserAuthenticated === false && (<LoggedOut setRenderAuthScreen={setRenderAuthScreen} />)}
        {isUserAuthenticated === true && (<LoggedIn />)}
      </div>
      {renderAuthScreen && (<AuthScreen />)}
    </>

  )
}

export default Header;