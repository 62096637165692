import React from 'react'
import { useNavigate } from 'react-router-dom';


const Error = () => {
    const Navigate = useNavigate();

    return (
        <div className=' w-screen h-screen flex items-center justify-center'>
            <h1 className=' text-2xl font-semibold'>May be your on broken url <span className=' text-3xl font-bold italic text-primary-800 cursor-pointer' onClick={() => Navigate('/')}>click</span> here for homepage</h1>
        </div>
    )
}

export default Error;