import { createSlice } from "@reduxjs/toolkit";





const user = createSlice({
    name: 'Applicant',
    initialState: {},
    reducers: {
        add: (state, action) => {
            return action.payload;
        }
    }
});

export const { add } = user.actions;
export default user.reducer; 