import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const profileUrl = 'https://tcap.pbworks.com/f/1435170280/myAvatar.png';

const LoggedIn = () => {
    const [showBox, setShowBox] = useState(false);
    const Navigate = useNavigate();
    return (
        <div className='relative'>
            <img src={profileUrl}
                onClick={() => setShowBox( pre => !pre)}
                className=' w-10 h-10 rounded-full cursor-pointer'
                alt="profile" />
            {showBox && (
                <div className=' absolute flex flex-col gap-1 p-2'>
                <span>LogOut</span>
                <span onClick={()=>Navigate('/profile')}>view Profile</span>
                <span>Setting</span>
            </div>)}

        </div>
    )
}

export default LoggedIn;