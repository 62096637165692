import React, { useContext } from 'react'
import { USER_AUTH_VALIDATION } from '../Utils/apis';
import { useDispatch } from 'react-redux';
import { add } from '../Store/Slices/user'
import { updateAuthenticationState } from '../Store/Slices/states';

let isCheckedPerformed = false;
let loading = false;
const useAuthValidation = () => {
    const dispatch = useDispatch();

    return async () => {
        if (!isCheckedPerformed && !loading) {
            try {
                loading = true;
                const res = await fetch(USER_AUTH_VALIDATION, {
                    method: 'POST',
                    credentials: 'include'
                });

                if (res.ok) {
                    const user = await res.json();
                    dispatch(add(user));
                    dispatch(updateAuthenticationState(true));
                }
                else {
                    throw new Error();
                }
            } catch (error) {
                dispatch(updateAuthenticationState(false));
            }
            finally {
                loading = false;
                isCheckedPerformed = true;
            }
        }
    }
}

export default useAuthValidation;