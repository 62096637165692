import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Error from "../Components/Error";
import { Provider } from "react-redux";
import store from "../Store/store";
import AuthContextProvider from "../Contexts/authContext";
import Register from "../Components/Auth/Register";
import Profile from "../Components/Profile/Profile";
import Login from "../Components/Auth/Login";
import Auth from "../Components/Auth/Auth";

const Homepage = lazy(() => import('../Pages/Homepage'));
// import Homepage from "../Pages/Homepage";

const routes = createBrowserRouter([{
    path: '/',
    // element: <Provider store={store}><AuthContextProvider><App /></AuthContextProvider></Provider>,
    element: <Provider store={store}><App /></Provider>,
    errorElement: <Error />,
    children: [
        {
            path: '/',
            element: <Homepage />
        },
        {
            path: '/auth',
            element: <Auth />,
            children: [
                {
                    path: '/auth/register',
                    element: <Register />
                },
                {
                    path: '/auth/login',
                    element: <Login />
                },
            ],
        }
        ,
        {
            path: '/profile',
            element: <Profile />
        }
    ]

}]);

export default routes;