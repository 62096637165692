import { configureStore } from "@reduxjs/toolkit";
import user from './Slices/user';
import states from './Slices/states'

const store = configureStore({
    reducer: {
        user,
        states,
    }
});

export default store;