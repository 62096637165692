import React from 'react';
import { useState, useEffect } from 'react';
import Login from '../Auth/Login';

const AuthScreen = () => {
    const [scrolledPx, setScrollPx] = useState(window.scrollY);

    useEffect(() => {
        const body = document.querySelector('body');
        const app = document.querySelector('html');

        app.style.scrollBehavior = 'auto'
        body.style.position = "fixed";
        body.style.top = `-${scrolledPx}px`;
        return () => {
            body.style.position = "";
            window.scrollTo(0, scrolledPx);
            app.style.scrollBehavior = 'smooth'
        }
    }, []);
    return (
        <div className=' fixed z-[100] w-screen flex justify-center bg-[#1b1b1b91]' style={{ height: "calc(100vh + 8rem)", top: '0px' }}>
            <Login />
        </div>
    )
}

export default AuthScreen;