import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { USER_LOGIN } from '../Utils/apis';
import { updateAuthenticationState } from '../Store/Slices/states';

const useLogIn = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const fn = async (Email, Password) => {
        if (!loading) {
            try {
                setLoading(true)
                const res = await fetch(USER_LOGIN, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ Email, Password }),
                });

                if (res.ok) {
                    const user = await res.json();
                    // dispatch(add(user));
                    dispatch(updateAuthenticationState(true));
                }
                else {
                    const err = await res.json();
                    throw new Error(err.error);
                }
            } catch (error) {
                dispatch(updateAuthenticationState(false));
                setError(error.message);
            }
            finally {
                setLoading(false);
            }
        }
    }

    return [loading, error, fn];
}

export default useLogIn;
