import { createSlice } from "@reduxjs/toolkit";

const states = createSlice({
    name: 'globalStates',
    initialState: {
        isUserAuthenticated: null,
    },
    reducers: {
        updateAuthenticationState: (state, action) => { return { ...state, isUserAuthenticated: action.payload } }
    }
});

export const { updateAuthenticationState } = states.actions;
export default states.reducer;