import { Outlet } from "react-router-dom";
import Header from "./Components/Header";
import { useEffect, useState } from "react";
import useAuthValidation from "./Hooks/useAuthValidation";

function App() {

  useAuthValidation()();

  return (
    <div className="App">
      <Header />
      <Outlet />
    </div>
  );
}

export default App;