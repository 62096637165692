import { createContext, useState } from "react";


export const AuthContext = createContext();

const AuthContextProvider = ({children}) => {

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    return <AuthContext.Provider value={{ isUserAuthenticated, setIsUserAuthenticated }}> {children}</AuthContext.Provider>
};
export default AuthContextProvider;