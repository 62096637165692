import React, { useState } from 'react'
import useLogIn from '../../Hooks/useLogIn';
import Email from './Email';
import { useNavigate } from 'react-router-dom';

const Login = () => {


    const invalidPasswordError = 'Invalid credentials';
    const Navigate = useNavigate();
    const [loading, error, logInFunction] = useLogIn();
    const [values, setValues] = useState({ email: '', password: '' });
    const [errors, setErrors] = useState({ email: true, password: '' });
    // const [wrongAttemptsCount, setWrongAttemptsCount] = useState(0);

    return (
        <div className=' fixed z-[100] w-screen flex justify-center bg-[#1b1b1b91]' style={{ height: "calc(100vh + 8rem)", top: '0px' }}>
            <form
                onSubmit={(e) => e.preventDefault()}
                className='p-4 rounded-xl min-w-96'
                style={{ "boxShadow": "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
            >
                <Email
                    email={values.email}
                    setEmail={(text) => setValues(pre => { return { ...pre, email: text } })}
                    setEmailValid={(bool) => setErrors(pre => { return { ...pre, email: bool } })}
                />
                <div>
                    <div className='mb-1 flex gap-2 items-end'>
                        <p className=' font-semibold'>Password</p>
                    </div>
                    <input
                        className=' py-1 px-2 outline-none rounded-md border-[1px] border-gray-400 w-full'
                        type='password'
                        placeholder='create your password'
                        value={values.password}
                        onChange={(e) => {
                            setValues(pre => { return { ...pre, password: e.target.value } });
                            if (errors.password) { setErrors(pre => { return { ...pre, password: '' } }); }
                        }}
                    />
                </div>

                {error?.password && (<p className='text-sm mt-4 text-[#ff0000]'>{errors?.password}</p>)}
                {
                    loading ? (<p>loading...</p>) : (
                        <button
                            className='mt-4 px-4 py-2 rounded-lg text-white bg-primary-800 hover:bg-primary-500 hover:cursor-pointer'
                            onClick={() => !errors.email && values.password.length >= 6 && logInFunction(values.email, values.password).then(() => { }).catch((error) => {
                                setValues(pre => { return { ...pre, password: '' } })
                                setErrors((pre) => { return { ...pre, password: error.message } })
                                if (error.message === invalidPasswordError) {
                                    // setWrongAttemptsCount(pre => pre += 1)
                                }
                            })}
                        >
                            LogIn
                        </button>
                    )
                }

                <p className='mt-4 text-xs font-semibold text-gray-900'>New user
                    <span
                        className='ml-1 text-primary-800 font-bold cursor-pointer hover:underline'
                        onClick={() => Navigate('/auth/register')}
                    >sign in </span>here
                </p>
            </form>
        </div>
    )
}

export default Login;
// {
//     wrongAttemptsCount >= 2 && (<p onClick={() => Navigate('/auth/forgetpassword')} className='text-sm mt-1 text-[#ff0000]'>forget password</p>)
// }
// {
//     wrongAttemptsCount < 2 && errors?.password && (<p className='text-sm mt-4 text-[#ff0000]'>{errors?.password}</p>)
// }